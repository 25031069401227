@import "../../../assets/styles/sass-resources/variables";

// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

.error-wrapper {
  padding: 1rem;
  background-color: $error-background;
  color: $error-text;
  margin: 1rem auto;
  border: 1px solid $error-text;
  border-radius: 0.25rem;
}
