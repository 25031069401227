@import "../../../assets/styles/sass-resources/variables";

// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

.modal {
  position: absolute;
  width: 40rem;
  height: auto;
  top: 50%;
  left: 50%;
  margin-left: -20rem;
  margin-top: -5rem;
  padding: 1rem;
  background-color: $modal-background;
  border-radius: 0.2rem;
  box-shadow: 4px 8px 27px -5px $shadow;
  outline: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $modal-backdrop;
}

.buttons-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  gap: 1rem;
}