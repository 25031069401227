@import "../../assets/styles/sass-resources/variables";

// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.login-button {
  font-weight: 800;
  line-height: 24px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: $white;
  background-color: $primary;
  border: none;
  margin: 1rem auto;

  &:disabled {
    background-color: $button-disabled-background;
  }
}