@import "../../../assets/styles/sass-resources/variables";

// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

.styled-nav {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0;
}
