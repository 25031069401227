@import "../../../assets/styles/sass-resources/variables";

// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

.table-with-buttons {
  display: flex;
  flex-direction: column;
}

.buttons-wrapper {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  &.top {
    justify-content: flex-end;
  }
  &.bottom {
    justify-content: center;
  }
}

.table {
  display: flex;
  flex-direction: row;
  background-color: $white;
  padding: 1rem 0.3rem;
  margin: 1rem 0;
  border-radius: 0.2rem;
  box-shadow: 4px 8px 27px -5px $shadow;
  overflow: auto;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.sort-icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 1.2rem;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.cell {
  border: 1px solid $shadow;
  color: $table-body-cell-text;
  padding: 0 0.5rem;
  gap: 0.5rem;
  line-height: 1.5;
  font-size: 1rem;
  min-height: 1.5rem;
  max-height: 1.5rem;
  white-space: nowrap;
  box-sizing: content-box;

  &.edited {
    font-weight: bold;
  }
  &.editable {
    cursor: pointer;
  }
}

.table-body-cell-input {
  outline: none;
  border: none;
  padding: 0;
  width: 100%;
}

.table-body-cell-value {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.header {
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  user-select: none;

  .cell {
    display: flex;
    color: $black;
    font-weight: 500;
    padding: 0 0.5rem;
    margin-bottom: 0.5rem;
    box-shadow: 4px 8px 7px -5px $shadow;
  }
}
