// COLORS

$primary: #ff5e2a;
$button-disabled-background: #C0C0C0;
$error-text: #FF0000;
$error-background: #FFD4D2;
$table-body-cell-text: #656565;
$shadow: #cbcbcb;
$main-background: #e7e7e7;
$modal-background: #f0f0f0;
$modal-backdrop: #cbcbcb80;

// Variables

$header-height: 70px;
