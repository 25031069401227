@import "../../../assets/styles/sass-resources/variables";

// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

.header {
  width: 100%;
  height: $header-height;
  background: $primary;
  display: flex;
  align-items: center;
  padding: 0px 200px 0px 10px;
}

.left-side-text {
  display: flex;
  width: 200px;
  background: $primary;
  color: $white;
  font-weight: 500;
  padding-left: 1rem;
  margin: 0;
}

.title {
  font-weight: 500;
  color: $white;
  opacity: 0;
  text-align: center;
  margin: 0 auto;
  flex-grow: 1;
  @include media-breakpoint-up(sm) {
    opacity: 1;
  }
}
