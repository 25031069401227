@import "../../../assets/styles/sass-resources/variables";

// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

.button {
  min-width: 10rem;
  padding: 0 1rem;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.2rem;

  &.classic {
    background: $white;
    color: $black;
  }

  &.primary {
    background: $primary;
    color: $white;
  }
}

.icon {
  font-size: 1.2rem;
}