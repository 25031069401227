@import "../../../assets/styles/sass-resources/variables";

// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

.loader {
  width: 2.5rem;
  height: 2.5rem;
  --bs-spinner-border-width: 0.3rem;
}